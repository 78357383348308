import React, { useState } from "react";
import Typewriter from "typewriter-effect";
import "./Experts.css";

function Experts() {
  const [business, setBusiness] = useState("");
  const [location, setLocation] = useState("قم");
  const [results, setResults] = useState("");
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch("http://localhost/bahar/experts.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ business, location }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }
        setResults(data.results || "هیچ نتیجه‌ای پیدا نشد!");
        setFormVisible(false);
      })
      .catch((error) => {
        setResults(`خطا: ${error.message}`);
        setFormVisible(false);
      })
      .finally(() => setLoading(false));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(results);
    alert("متن کپی شد!");
  };

  const resetForm = () => {
    setBusiness("");
    setLocation("قم");
    setResults("");
    setFormVisible(true);
  };

  return (
    <div className="experts-container">
      {formVisible ? (
        <form className="experts-form fade-in" onSubmit={handleSubmit}>
          <h1>افراد خفن هر کسب و کار</h1>
          <div className="form-group">
            <label htmlFor="business">حوزه کسب و کار:</label>
            <input
              type="text"
              id="business"
              value={business}
              onChange={(e) => setBusiness(e.target.value)}
              placeholder="مثال: آبمیوه‌فروشی"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="location">مکان:</label>
            <input
              type="text"
              id="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="مثال: قم"
              required
            />
          </div>
          <button type="submit" className="submit-button">
            {loading ? "در حال جستجو..." : "نمایش افراد خفن"}
          </button>
        </form>
      ) : (
        <div className="results fade-in">
          <h2>نتایج:</h2>
          <div className="typed-results">
            <Typewriter
              options={{
                strings: results,
                autoStart: true,
                loop: false,
                delay: 10,
              }}
            />
          </div>
          <div className="actions">
            <button onClick={handleCopy} className="copy-button">
              کپی نتایج
            </button>
            <button onClick={resetForm} className="back-button">
              بازگشت
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Experts;
