import React, { useState } from "react";

function GuideFile() {
  const [article, setArticle] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setError(null);
    setResponse(null);

    const data = {
      prompt: `
لطفاً بر اساس مقاله زیر، راهنمایی کامل برای بارگذاری هوشمندانه و حرفه‌ای مقاله در سایت ارائه دهید:

1. کلمات کلیدی اصلی و فرعی:
مشخص کنید که مقاله با چه کلمات کلیدی اصلی و فرعی سئو شده است.
توضیح دهید که URL مناسب برای این مقاله چه باشد. (فارسی باشد URL)

2. متای توضیحات (Meta Description):
متن پیشنهادی برای متا توضیحات را به طور دقیق ارائه دهید که شامل کلمه کلیدی اصلی باشد و کاربران را به کلیک ترغیب کند.
طول متا توضیحات: بین 140 تا 160 کاراکتر باشد.

3. ساختار تیترها و متن:
بررسی کنید که تیترها (H1، H2، H3) به درستی ساختاربندی شده باشند و از کلمات کلیدی به شکل طبیعی استفاده شده باشد.
مطمئن شوید که پاراگراف‌ها کوتاه، با فاصله مناسب، و خوانایی بالا باشند.

متن مقاله:
${article}
      `,
    };

    fetch("http://localhost/bahar/guidefile.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP ${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then((result) => {
        setLoading(false);
        if (result.result) {
          setResponse(result.result);
        } else {
          setError("هیچ پاسخی دریافت نشد. لطفاً مقاله را بررسی کنید.");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message || "خطایی رخ داد. لطفاً دوباره تلاش کنید.");
      });
  };

  return (
    <div style={{ textAlign: "center", padding: "20px", fontFamily: "Vazir" }}>
      <h1>راهنمای بارگذاری مقاله</h1>
      <p>مقاله خود را وارد کنید تا راهنمایی‌های بهینه‌سازی دریافت کنید:</p>

      <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
        <div style={{ marginBottom: "15px" }}>
          <textarea
            value={article}
            onChange={(e) => setArticle(e.target.value)}
            placeholder="متن مقاله خود را اینجا وارد کنید..."
            required
            style={{
              width: "80%",
              height: "200px",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          ></textarea>
        </div>
        <button
          type="submit"
          disabled={loading}
          style={{
            padding: "10px 20px",
            backgroundColor: loading ? "#ccc" : "#38aeb8",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: loading ? "not-allowed" : "pointer",
          }}
        >
          {loading ? "در حال پردازش..." : "دریافت راهنمایی"}
        </button>
      </form>

      {error && (
        <div
          style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#f2dede",
            color: "#a94442",
            border: "1px solid #ebccd1",
            borderRadius: "5px",
          }}
        >
          <h3>خطا:</h3>
          <p>{error}</p>
        </div>
      )}

      {response && (
        <div
          style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#dff0d8",
            color: "#3c763d",
            border: "1px solid #d6e9c6",
            borderRadius: "5px",
            textAlign: "left",
            direction: "rtl",
            lineHeight: "1.8",
          }}
        >
          <h3>راهنمایی بهینه‌سازی:</h3>
          <p>{response}</p>
        </div>
      )}
    </div>
  );
}

export default GuideFile;
