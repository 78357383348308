import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const location = useLocation(); // بررسی مسیر فعلی

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">
        <img src="/bahar.webp" alt="بهار" className="logo" />
        
      </Link>
      {location.pathname !== "/" && (
        <Link to="/" className="home-button">
        <i className="fas fa-home home-icon"></i>
        بازگشت به صفحه اصلی
      </Link>
      
      )}
    </nav>
  );
}

export default Navbar;
