import React, { useState } from "react";
import Typewriter from "typewriter-effect";

function Article() {
  const [link, setLink] = useState("");
  const [content, setContent] = useState(""); // فیلد متن
  const [tone, setTone] = useState("دوستانه");
  const [cta, setCta] = useState("تماس بگیرید");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [savedLinks, setSavedLinks] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setError(null);
    setResponse(null);

    // ذخیره لینک و متن وارد شده
    setSavedLinks((prev) => [...new Set([link || content, ...prev])]);

    const data = {
      command: `
پرامت نوشتن مقاله:
${link ? `لینک نمونه: ${link}` : ""}
${content ? `متن نمونه: ${content}` : ""}
لحن: ${tone}
دعوت به اقدام: ${cta}

هدف: بازنویسی و تولید مقاله‌ای جذاب، عنوان مقاله همان باشد ولی متفاوت از متن اولیه، با افزودن نکات جدید و ارائه ارزش بیشتر به خواننده.
      `,
    };

    fetch("http://localhost/bahar/articles.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP ${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then((result) => {
        setLoading(false);
        setResponse(result.response || "نتیجه‌ای دریافت نشد.");
        setSubmitted(true);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message || "خطایی رخ داد.");
      });
  };

  const resetForm = () => {
    setLink("");
    setContent("");
    setTone("دوستانه");
    setCta("تماس بگیرید");
    setSubmitted(false);
    setResponse(null);
    setError(null);
  };

  return (
    <div style={{ textAlign: "center", padding: "20px", fontFamily: "Vazir" }}>
      <h1>صفحه مقالات</h1>
      <p>لینک یا متن و اطلاعات لازم برای بازنویسی مقاله را وارد کنید:</p>

      {/* فرم و دکمه‌ها */}
      {!submitted && (
        <>
          <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
            <div style={{ marginBottom: "15px" }}>
              <label>لینک نمونه (اختیاری):</label>
              <br />
              <input
                type="url"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder="https://example.com"
                style={{
                  width: "80%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label>متن نمونه (اختیاری):</label>
              <br />
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="متن نمونه را اینجا وارد کنید..."
                style={{
                  width: "80%",
                  height: "100px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              ></textarea>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label>لحن:</label>
              <br />
              <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                {["دوستانه", "رسمی", "انگیزشی"].map((option) => (
                  <button
                    type="button"
                    key={option}
                    onClick={() => setTone(option)}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: tone === option ? "#38aeb8" : "#f0f0f0",
                      color: tone === option ? "white" : "black",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label>دعوت به اقدام (CTA):</label>
              <br />
              <select
                value={cta}
                onChange={(e) => setCta(e.target.value)}
                style={{
                  width: "80%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              >
                <option value="تماس بگیرید">تماس بگیرید</option>
                <option value="همین حالا ثبت‌نام کنید">همین حالا ثبت‌نام کنید</option>
                <option value="بیشتر بدانید">بیشتر بدانید</option>
              </select>
            </div>
            <button
              type="submit"
              style={{
                padding: "10px 20px",
                backgroundColor: "#38aeb8",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {loading ? "در حال ارسال..." : "ارسال به سرور"}
            </button>
          </form>
        </>
      )}

      {/* نمایش نتیجه */}
      {response && (
        <div
          style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#dff0d8",
            color: "#3c763d",
            border: "1px solid #d6e9c6",
            borderRadius: "5px",
          }}
        >
          <h3>نتیجه:</h3>
          <Typewriter
            options={{
              strings: response,
              autoStart: true,
              loop: false,
            }}
          />
        </div>
      )}

      {/* نمایش خطا */}
      {error && (
        <div
          style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#f2dede",
            color: "#a94442",
            border: "1px solid #ebccd1",
            borderRadius: "5px",
          }}
        >
          <h3>خطا:</h3>
          <p>{error}</p>
        </div>
      )}

      {/* دکمه بازگشت */}
      {submitted && (
        <button
          onClick={resetForm}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#38aeb8",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          فرم جدید
        </button>
      )}
    </div>
  );
}

export default Article;
