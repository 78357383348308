import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import "./Home.css";

function Home() {
  const [exiting, setExiting] = useState(false);

  const handleExit = (e) => {
    e.preventDefault();
    setExiting(true);

    setTimeout(() => {
      window.location.href = e.target.href;
    }, 500);
  };

  return (
    <div className={`home ${exiting ? "exiting" : ""}`}>
      <h1>خوش آمدید به بهار من</h1>
      <div className="buttons-container">
        <Link to="/article" className="button" onClick={handleExit}>
          مقاله
        </Link>
        <Link to="/scenario" className="button" onClick={handleExit}>
          سناریو
        </Link>
        <Link to="/sports-planning" className="button" onClick={handleExit}>
          برنامه ریزی ورزشی
        </Link>
        <Link to="/marketing" className="button" onClick={handleExit}>
          بازاریابی
        </Link>
        <Link to="/story-design" className="button" onClick={handleExit}>
          طراحی عکس استوری
        </Link>
        <Link to="/experts" className="button" onClick={handleExit}>
          افراد خفن هر کسب و کار
        </Link>
        <Link to="/guide-file" className="button" onClick={handleExit}>
          فایل راهنما
        </Link>
        <Link to="/ad-revision" className="button" onClick={handleExit}>
          اصلاح متن تبلیغاتی
        </Link>
        <Link to="/copywriting" className="button" onClick={handleExit}>
          تبلیغ نویسی
        </Link>
        <Link to="/competitor-value" className="button" onClick={handleExit}>
          ارزش پیشنهادی رقبا
        </Link>
        <Link to="/data-analysis" className="button" onClick={handleExit}>
          تحلیل داده
        </Link>
        <Link to="/programming" className="button" onClick={handleExit}>
          برنامه نویسی
        </Link>
        <Link to="/instagram-analysis" className="button" onClick={handleExit}>
          تحلیل پیج اینستاگرام
        </Link>
      
       
      </div>
      <div className="footer">
        <p>
          <Typewriter
            options={{
              strings: [
                "بهار من  بهبود کسب و کار شما...",
                "ایده‌های خلاقانه در پولسازی...",
                "مارکتینگ حرفه‌ای با ما...",
              ],
              autoStart: true,
              loop: true,
              delay: 50,
              deleteSpeed: 30,
              pauseFor: 2000,
            }}
          />
        </p>
        <p>
          <a href="https://bahareman.com/" target="_blank" rel="noopener noreferrer">
            bahareman.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default Home;
