import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";
import Typewriter from "typewriter-effect";

function Scenario() {
  const [business, setBusiness] = useState("");
  const [contentType, setContentType] = useState("ریلز");
  const [mediaType, setMediaType] = useState("فیلم");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!business.trim()) {
      setError("لطفاً نام کسب‌وکار را وارد کنید.");
      return;
    }

    setLoading(true);
    setError(null);
    setResponse(null);

    const data = {
      business,
      contentType,
      mediaType,
    };

    fetch("http://localhost/bahar/scenario.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP ${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then((result) => {
        setLoading(false);
        if (result.status === "success") {
          setResponse(result.scenario);
        } else {
          setError(result.error || "خطایی رخ داد.");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message || "خطایی رخ داد.");
      });
  };

  const resetForm = () => {
    setBusiness("");
    setContentType("ریلز");
    setMediaType("فیلم");
    setError(null);
    setResponse(null);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("متن کپی شد!");
  };

  return (
    <div style={{ textAlign: "center", padding: "20px", fontFamily: "Vazir", animation: "fadeIn 1s ease" }}>
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(20px); }
            to { opacity: 1; transform: translateY(0); }
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <h1>سناریوی تولید محتوا</h1>
      {!loading && !response && !error && (
        <form onSubmit={handleSubmit} style={{ marginTop: "20px", animation: "fadeIn 1s ease" }}>
          <div style={{ marginBottom: "15px" }}>
            <label>نام کسب‌وکار:</label>
            <br />
            <input
              type="text"
              value={business}
              onChange={(e) => setBusiness(e.target.value)}
              placeholder="نام کسب‌وکار خود را وارد کنید"
              required
              style={{
                width: "80%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontFamily: "Vazir",
              }}
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <label>نوع محتوا:</label>
            <br />
            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
              <button
                type="button"
                onClick={() => setContentType("ریلز")}
                style={{
                  padding: "10px 20px",
                  backgroundColor: contentType === "ریلز" ? "#38aeb8" : "#f0f0f0",
                  color: contentType === "ریلز" ? "white" : "black",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                ریلز
              </button>
              <button
                type="button"
                onClick={() => setContentType("پست")}
                style={{
                  padding: "10px 20px",
                  backgroundColor: contentType === "پست" ? "#38aeb8" : "#f0f0f0",
                  color: contentType === "پست" ? "white" : "black",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                پست
              </button>
            </div>
          </div>
          <div style={{ marginBottom: "15px" }}>
            <label>نوع رسانه:</label>
            <br />
            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
              <button
                type="button"
                onClick={() => setMediaType("فیلم")}
                style={{
                  padding: "10px 20px",
                  backgroundColor: mediaType === "فیلم" ? "#38aeb8" : "#f0f0f0",
                  color: mediaType === "فیلم" ? "white" : "black",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                فیلم
              </button>
              <button
                type="button"
                onClick={() => setMediaType("تصویر")}
                style={{
                  padding: "10px 20px",
                  backgroundColor: mediaType === "تصویر" ? "#38aeb8" : "#f0f0f0",
                  color: mediaType === "تصویر" ? "white" : "black",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                تصویر
              </button>
            </div>
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#ec344c",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            دریافت سناریو
          </button>
        </form>
      )}

      {/* پیش‌لودر */}
      {loading && (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              width: "50px",
              height: "50px",
              border: "5px solid #ccc",
              borderTop: "5px solid #38aeb8",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
              margin: "0 auto",
            }}
          ></div>
        </div>
      )}

      {/* نمایش سناریو */}
      {response && (
        <div
          style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#dff0d8",
            color: "#3c763d",
            border: "1px solid #d6e9c6",
            borderRadius: "5px",
            textAlign: "right",
            direction: "rtl",
            position: "relative",
          }}
        >
          <button
            onClick={() => copyToClipboard(response)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "transparent",
              border: "none",
              color: "#38aeb8",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            <FaCopy />
          </button>
          <h3>سناریوی پیشنهادی:</h3>
          <Typewriter
            options={{
              strings: response,
              autoStart: true,
              loop: false,
              delay: 20,
            }}
          />
        </div>
      )}

      {/* نمایش خطا */}
      {error && (
        <div
          style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#f2dede",
            color: "#ec344c",
            border: "1px solid #ebccd1",
            borderRadius: "5px",
          }}
        >
          <h3>خطا:</h3>
          <p>{error}</p>
        </div>
      )}

      {/* دکمه بازگشت */}
      {(response || error) && (
        <button
          onClick={resetForm}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#ec344c",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          فرم جدید
        </button>
      )}
    </div>
  );
}

export default Scenario;
