import React, { useState } from "react";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";

function SportsPlanning() {
  const [gender, setGender] = useState("مرد");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [age, setAge] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setError(null);
    setResponse(null);

    const data = {
      gender,
      height,
      weight,
      age,
      prompt: `
بر اساس اطلاعات زیر، برنامه رژیم غذایی و باشگاهی برای فرد را برای 7 روز هفته به صورت اختصاصی ارائه بده. جمعه‌ها استراحت باشد یا هر مورد دیگری که صلاح می‌دانی:
- جنسیت: ${gender}
- قد: ${height} سانتی‌متر
- وزن: ${weight} کیلوگرم
- سن: ${age} سال
      `,
    };

    fetch("http://localhost/bahar/sports-planning.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP ${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then((result) => {
        setLoading(false);
        if (result.choices && result.choices[0]?.message?.content) {
          setResponse(result.choices[0].message.content);
        } else {
          setError("هیچ پاسخی دریافت نشد. لطفاً اطلاعات ورودی را بررسی کنید.");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message || "خطایی رخ داد. لطفاً دوباره تلاش کنید.");
      });
  };

  const handleReset = () => {
    setGender("مرد");
    setHeight("");
    setWeight("");
    setAge("");
    setResponse(null);
    setError(null);
  };

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5 }}
      variants={fadeIn}
      style={{ textAlign: "center", padding: "20px", fontFamily: "Vazir" }}
    >
      <h1>برنامه ورزشی و رژیم غذایی</h1>
      <p>اطلاعات خود را وارد کنید تا برنامه اختصاصی دریافت کنید:</p>

      {!loading && !response && (
        <motion.form
          onSubmit={handleSubmit}
          style={{ marginTop: "20px" }}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div style={{ marginBottom: "15px" }}>
            <label>جنسیت:</label>
            <br />
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              style={{
                width: "50%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            >
              <option value="مرد">مرد</option>
              <option value="زن">زن</option>
            </select>
          </div>
          <div style={{ marginBottom: "15px" }}>
            <label>قد (سانتی‌متر):</label>
            <br />
            <input
              type="number"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              required
              style={{
                width: "50%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <label>وزن (کیلوگرم):</label>
            <br />
            <input
              type="number"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              required
              style={{
                width: "50%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <label>سن (سال):</label>
            <br />
            <input
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              required
              style={{
                width: "50%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            style={{
              padding: "10px 20px",
              backgroundColor: loading ? "#ccc" : "#38aeb8",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: loading ? "not-allowed" : "pointer",
            }}
          >
            دریافت برنامه
          </button>
        </motion.form>
      )}

      {loading && (
        <div style={{ marginTop: "20px" }}>
          <div className="spinner"></div>
          <p style={{ fontSize: "18px", color: "#38aeb8", marginTop: "10px" }}>
            در حال پردازش...
          </p>
        </div>
      )}

      {error && (
        <motion.div
          style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#f2dede",
            color: "#a94442",
            border: "1px solid #ebccd1",
            borderRadius: "5px",
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5 }}
        >
          <h3>خطا:</h3>
          <p>{error}</p>
          <button
            onClick={handleReset}
            style={{
              padding: "10px 20px",
              backgroundColor: "#38aeb8",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            شروع مجدد
          </button>
        </motion.div>
      )}

      {response && (
        <motion.div
          style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#dff0d8",
            color: "#3c763d",
            border: "1px solid #d6e9c6",
            borderRadius: "5px",
            textAlign: "right",
            direction: "rtl",
            lineHeight: "1.8",
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5 }}
        >
          <h3>برنامه شما:</h3>
          <Typewriter
            options={{
              strings: response,
              autoStart: true,
              loop: false,
            }}
          />
          <button
            onClick={handleReset}
            style={{
              padding: "10px 20px",
              backgroundColor: "#38aeb8",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            شروع مجدد
          </button>
        </motion.div>
      )}
    </motion.div>
  );
}

export default SportsPlanning;
