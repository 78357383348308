import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Article from "./pages/Article";
import Marketing from "./pages/Marketing";
import Scenario from "./pages/Scenario";
import Copywriting from "./pages/Copywriting";
import Experts from "./pages/Experts";
import AdRevision from "./pages/AdRevision";
import StoryDesign from "./pages/StoryDesign";
import CompetitorValue from "./pages/CompetitorValue";
import DataAnalysis from "./pages/DataAnalysis";
import Programming from "./pages/Programming";
import InstagramAnalysis from "./pages/InstagramAnalysis";
import GuideFile from "./pages/GuideFile";
import SportsPlanning from "./pages/SportsPlanning";

function App() {
  return (
    <Router>
      <div className="app">
        {/* هدر */}
        <Navbar />

        {/* مسیرها */}
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/article" element={<Article />} />
            <Route path="/marketing" element={<Marketing />} />
            <Route path="/scenario" element={<Scenario />} />
            <Route path="/copywriting" element={<Copywriting />} />
            <Route path="/experts" element={<Experts />} />
            <Route path="/ad-revision" element={<AdRevision />} />
            <Route path="/story-design" element={<StoryDesign />} />
            <Route path="/competitor-value" element={<CompetitorValue />} />
            <Route path="/data-analysis" element={<DataAnalysis />} />
            <Route path="/programming" element={<Programming />} />
            <Route path="/instagram-analysis" element={<InstagramAnalysis />} />
            <Route path="/guide-file" element={<GuideFile />} />
            <Route path="/sports-planning" element={<SportsPlanning />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
