import React, { useState } from "react";

function StoryDesign() {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [selectedMode, setSelectedMode] = useState("هنری");
  const [submitted, setSubmitted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setError(null);
    setImage(null);

    const data = {
      prompt: `${text} (حالت: ${selectedMode})`,
    };

    fetch("http://localhost/bahar/imagine.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP ${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then((result) => {
        setLoading(false);
        setSubmitted(true);
        if (result.image_url) {
          setImage(result.image_url);
        } else {
          setError("هیچ تصویری تولید نشد. لطفاً متن ورودی خود را بازبینی کنید.");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message || "خطایی رخ داد. لطفاً دوباره تلاش کنید.");
      });
  };

  const resetForm = () => {
    setText("");
    setImage(null);
    setError(null);
    setSubmitted(false);
    setLoading(false);
  };

  return (
    <div style={{ textAlign: "center", padding: "20px", fontFamily: "Vazir" }}>
      <h1>ساخت تصویر استوری</h1>
      <p>متنی وارد کنید تا تصویر مربوط به آن ساخته شود:</p>

      {!submitted && (
        <div
          style={{
            transition: "opacity 0.5s ease, transform 0.5s ease",
            opacity: loading ? 0 : 1,
            transform: loading ? "translateY(-50px)" : "translateY(0)",
            pointerEvents: loading ? "none" : "auto",
          }}
        >
          <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
            <div style={{ marginBottom: "15px" }}>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="اینجا متن خود را وارد کنید..."
                required
                style={{
                  width: "80%",
                  height: "100px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              ></textarea>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <h3>انتخاب حالت:</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {["هنری", "واقع‌گرایانه", "فانتزی"].map((mode) => (
                  <button
                    type="button"
                    key={mode}
                    onClick={() => setSelectedMode(mode)}
                    style={{
                      padding: "10px 20px",
                      backgroundColor:
                        selectedMode === mode ? "#38aeb8" : "#f0f0f0",
                      color: selectedMode === mode ? "white" : "black",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {mode}
                  </button>
                ))}
              </div>
            </div>
            <button
              type="submit"
              disabled={loading}
              style={{
                padding: "10px 20px",
                backgroundColor: loading ? "#ccc" : "#e83548",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: loading ? "not-allowed" : "pointer",
              }}
            >
              {loading ? "در حال ساخت تصویر..." : "ساخت تصویر"}
            </button>
          </form>
        </div>
      )}

      {loading && (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              width: "50px",
              height: "50px",
              border: "5px solid #ccc",
              borderTop: "5px solid #38aeb8",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
              margin: "0 auto",
            }}
          ></div>
          <style>
            {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
          </style>
        </div>
      )}

      {error && (
        <div
          style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#f2dede",
            color: "#a94442",
            border: "1px solid #ebccd1",
            borderRadius: "5px",
          }}
        >
          <h3>خطا:</h3>
          <p>{error}</p>
          <button
            onClick={resetForm}
            style={{
              marginTop: "10px",
              padding: "10px 20px",
              backgroundColor: "#38aeb8",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            تلاش مجدد
          </button>
        </div>
      )}

      {image && (
        <div style={{ marginTop: "20px" }}>
          <h3>تصویر ساخته‌شده:</h3>
          <img
            src={image}
            alt="ساخته شده توسط AI"
            style={{
              width: isFullScreen ? "100%" : "512px",
              height: isFullScreen ? "100%" : "512px",
              borderRadius: isFullScreen ? "0" : "10px",
              border: "1px solid #ccc",
              cursor: "pointer",
              transition: "all 0.5s ease",
            }}
            onClick={() => setIsFullScreen(!isFullScreen)}
          />
          {!isFullScreen && (
            <div style={{ marginTop: "10px" }}>
              <a
                href={image}
                download="generated_image.png"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#ec344c",
                  color: "white",
                  borderRadius: "5px",
                  textDecoration: "none",
                  marginRight: "10px",
                }}
              >
                دانلود تصویر
              </a>
              <button
                onClick={resetForm}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#38aeb8",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                ساخت تصویر جدید
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default StoryDesign;
