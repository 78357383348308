import React from "react";
import { Link } from "react-router-dom";

function InstagramAnalysis() {
  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>صفحه اصلاح متن تبلیغاتی</h1>
      <p>در اینجا مطالب مرتبط با تحلیل پیج قرار می‌گیرد.</p>
      <Link to="/" style={{ color: "blue", textDecoration: "none" }}>بازگشت به صفحه اصلی</Link>
    </div>
  );
}

export default InstagramAnalysis;
